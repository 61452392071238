import React, { useEffect, useState } from "react"
import Slider from "@material-ui/core/Slider";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@mui/material";

const SliderStep = ({ step, params, prices, raionsStep, roomsStep, index, length, nextStep, prevStep, form, setForm }) => {
    const [sliderValue, setValue] = useState(null)
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    const [defaultValue, setDefaultValue] = useState(null)
    const [currency, setCurrency] = useState(null)

    const valuetext = (value) => {
        return `${value} ${currency}`;
    }

    const stepS = parseFloat(step.step)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        form[step.title] = newValue
        setForm(form)
    };

    const getRaionsID = (raionName) => {
        for (let i=0; i<domainSettings.locations.length; i++) {
            if (domainSettings.locations[i].NameFull === raionName) {
                return domainSettings.locations[i].PortalID
            }
        }
    }
    useEffect(async ()=> {
        let minV = domainSettings.domain.custom_min_price?domainSettings.domain.custom_min_price:step.answers[0].param[0]
        let maxV = domainSettings.domain.custom_max_price?domainSettings.domain.custom_max_price:step.answers[0].param[1]
        console.log(step.answers[0])
        setMin(minV)
        setMax(maxV)
        setValue(minV)
        if (domainSettings.city.code !=="dubai") {
            setCurrency("руб.")
        } else {
            setCurrency("$")
        }
        
        form[step.title] = minV
        setForm(form)
    },[index])



    const sliderTheme = createTheme({
        overrides: {
            MuiSlider: {
                root: {
                    color: `#eaedf4 !important`,
                    height: 2,
                    padding: "13px 0",
                    marginTop: "50px",
                },
                track: {
                    height: 2,
                    borderRadius: 2,
                    color: `#74cdfb`
                },
                thumb: {
                    marginTop: "-20px",
                    width: "46px !important",
                    height: "46px !important",
                    background: `#fff !important`,
                    cursor: "pointer",
                    outlineStyle: "none",
                    border: `2px solid #74cdfb`,
                    webkitBoxSizing: "border-box",
                    mozBoxSizing: "border-box",
                    boxSizing: "border-box",
                    webkitBorderRadius: "50%",
                    borderRadius: "50%",
                }
            }
        }
    });

    if (min == null || max == null || sliderValue == null) {
        return <div>load</div>
    }

    return <div className="in_slider">
        <div className="prpp" id="price_info">{sliderValue === min ? "От" : "До"} {sliderValue !== undefined ? sliderValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "0"} {currency}</div>
        <input id="max_price" type="hidden" value={valuetext()} />
        <ThemeProvider theme={sliderTheme}>
            <Slider
                //defaultValue={defaultValue}
                getAriaValueText={valuetext}
                aria-labelledby="non-linear-slider"
                step={50000}
                value={sliderValue}
                onChange={handleChange}
                min={min}
                max={max}
            />
        </ThemeProvider>
    </div>
}

export default SliderStep