import "./politic.css"

export const Politic = ({site, date, close}) => {
    return <div className="pu_rgba">
        <div className="pu_table">
            <div className="pu_cell">
            <div className="pu_politic">
                    <div className="closeform" onClick={()=>close()}><img src="/file-store/closeform.svg" /></div>
                    <div className="pu_p_title">ПОЛИТИКА<br />обработки персональных данных и их конфиденциальности <br />ООО «ЛИДГЕН»
                    </div>
                    <div className="pu_p_head">
                        <span>г. Краснодар</span>
                        <span>«18» октября 2023 года</span>
                    </div>
                    <div className="pu_p_content">
                        <div className="pu_p_title">1.  ОБЩИЕ ПОЛОЖЕНИЯ</div>
                        <p>
                            1.1.    Настоящая Политика обработки Персональных данных и их конфиденциальности (далее по тексту – Политика) действует в отношении всей персональной информации, которую может получить о Пользователе Общество с ограниченной ответственностью «ЛИДГЕН» (ОГРН: 1182375058194, ИНН: 2308257764, юридический адрес: 350049, Краснодарский край, г. Краснодар, ул. Пластунская, д. 28, пом. 410) во время использования сайта «Новостройки», расположенного на доменном имени {site} (далее по тексту – Сайт).</p>
                        <p>
                            1.2.    Обработку Персональных данных осуществляет Общество с ограниченной ответственностью «ЛИДГЕН», зарегистрированное Межрайонной инспекцией Федеральной налоговой службы № 16 по Краснодарскому краю 09.07.2018 за ОГРН: 1182375058194 (далее по тексту – Оператор), а также уполномоченные Оператором лица на управление Сайтом (Администрация Сайта).</p>
                        <p>
                           1.3. Настоящая Политика составлена в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», Федеральным законом от 27.07.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите информации» и другими требованиями законодательства Российской̆ Федерации в области обработки и защиты Персональных данных и определяет порядок обработки Персональных данных и меры по обеспечению безопасности Персональных данных, и направлена на обеспечение сохранности и защиты Персональных данных с целью соблюдения и защиты прав и свобод каждого гражданина на неприкосновенность частной жизни, личную и семейную тайну, защиту своей чести и доброго имени.</p>
                        <p>
                            1.4.    Все вопросы, связанные с обработкой Персональных данных, не урегулированные настоящей Политикой, разрешаются в соответствии с действующим законодательством Российской Федерации в области Персональных данных.
                        </p>
                        <p>
                            1.5.    Использование Сайта Пользователем означает согласие с настоящей Политикой, то есть Пользователь подтверждает, что он принимает условия Политики и дает согласие на обработку своих Персональных данных Оператору и Администрации Сайта.
                        </p>
                        <p>
                           1.6. В случае несогласия с условиями Политики, Пользователь прекращает использование Сайта.</p>
                        <p>
                            1.7.    Администрация не проверяет достоверность Персональных данных, предоставляемых Пользователем. Ответственность за достоверность предоставленных данных лежит на Пользователе. Оператор и Администрация Сайта исходят из того, что Персональные данные предоставляются Пользователем добровольно, добросовестно, свободной волей и в своем интересе.
                        </p>
                        <p>
                            1.7.1.  Сознательно использует Сайт от своего имени и достоверно указывает информацию о себе в объеме и в случаях, когда это требуется для оставления Заявки на Сайте в случае наличия потребности в подборе недвижимости, рассмотрения предложений Оператора, получения дополнительной информации о деятельности Оператора, получения от Администрации Сайта ответов на вопросы Пользователя, а также в иных случаях, предусмотренных функционалом Сайта.
                        </p>
                        <p>
                            1.7.2.  Сознательно определил и контролирует настройки используемого им программного обеспечения в соответствии со своими предпочтениями относительно защиты информации, хранящейся на стороне браузера, информации о собственном аппаратно-программном обеспечении и интернет-соединении.
                        </p>
                        <p>
                            1.7.3.  Ознакомился и имеет возможность в любой момент ознакомиться с условиями Политики путем перехода по гипертекстовой ссылке, размещенной на Сайте.
                        </p>
                        <p>
                            1.8.    Предоставляя данные третьих лиц, необходимые для использования Сайта Пользователь подтверждает получение им согласия этих лиц на обработку их Персональных данных или наличие у Пользователя полномочий на выражение согласия от имени таких лиц, что является заверением об обстоятельствах.
                        </p>
                        <p>
                            1.9.    Актуальная версия Политики обработки Персональных данных и их конфиденциальности в свободном доступе размещена на Сайте. 
                        </p>
                        <p>
                            1.10.   Пользователь может получить любые разъяснения по интересующим вопросам, касающихся обработки его Персональных данных, направив запрос Оператору по адресу электронной почты: <a href="mailto:info@leadgen.market">info@leadgen.market</a>.
                        </p>
                       
                        <div className="pu_p_title">2.  ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</div>

                       <p>
                           В настоящей Политике, если из ее текста прямо не вытекает иное, следующие термины будут иметь указанные ниже значения:
                       </p>
                       <p>
                           2.1. <b>«Автоматизированная обработка персональных данных»</b> означает обработку Персональных данных с помощью средств вычислительной техники, в том числе посредством CRM-систем и специализированных сервисов.
                       </p>
                       <p>
                           2.2. <b>«Администрация Сайта»</b> означает лиц, уполномоченных Оператором на управление Сайтом, которые организуют и (или) осуществляют обработку Персональных данных, а также определяют цели обработки Персональных данных, состав Персональных данных, подлежащих обработке, действия (операции), совершаемые с Персональными данными.
                       </p>
                       <p>
                           2.3. <b>«Блокирование персональных данных»</b> означает временное прекращение обработки Персональных данных (за исключением случаев, если обработка необходима для уточнения Персональных данных).
                       </p>
                       <p>
                           2.4. <b>«Заявка»</b> означает оформленный на Сайте по форме Администрации запрос Пользователя о необходимости установления связи с ним (обратный звонок) для получения Пользователем дополнительной информации о деятельности Оператора, просмотру персональных предложений, подобранных Оператором, получения доступа к базе объектов недвижимости в соответствии с потребностями Пользователя. 
                       </p>
                       <p>
                           2.5. <b>«Обезличивание персональных данных»</b> означает действия, в результате которых невозможно определить без использования дополнительной информации принадлежность Персональных данных конкретному Пользователю или иному субъекту Персональных данных.
                       </p>
                       <p>
                           2.6. <b>«Обработка персональных данных»</b> означает любое действие (операцию) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с Персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных.
                       </p>
                       <p>
                           2.7. <b>«Оператор»</b> означает Общество с ограниченной ответственностью «ЛИДГЕН» (ОГРН: 1182375058194, ИНН: 2308257764, юридический адрес: 350049, Краснодарский край, г. Краснодар, ул. Пластунская, д. 28, пом. 410).
                       </p>
                       <p>
                           2.8. <b>«Конфиденциальность персональных данных»</b> означает обязательное для соблюдения Оператором или иным получившим доступ к Персональным данным лицом требование не допускать их распространения без согласия Пользователя или наличия иного законного основания.
                       </p>
                       <p>
                           2.9. <b>«Распространение персональных данных»</b> означает любые действия, направленные на раскрытие Персональных данных неопределенному кругу лиц (передача Персональных данных) или на ознакомление с Персональными данными неограниченного круга лиц, в том числе обнародование Персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к Персональным данным каким-либо иным способом.
                       </p>
                       <p>
                           2.10. <b>«Персональные данные»</b> означает любую информацию, относящуюся к прямо или косвенно определенному или определяемому физическому лицу (субъекту Персональных данных (Пользователю)).
                       </p>
                       <p>
                           2.11.    <b>«Предоставление персональных данных»</b> означает действия, направленные на раскрытие Персональных данных определенному лицу или определенному кругу лиц.
                       </p>
                       <p>
                           2.12.    <b>«Пользователь»</b> означает получателя услуг Оператора либо посетителя Сайта как субъектов Персональных данных.
                       </p>
                       <p>
                           2.13.    <b>«Сайт»</b> означает совокупность программ для электронных вычислительных машин и иной информации, обеспечивающий доступ к мультмедийному продукту Оператора посредством информационно-телекоммуникационной сети «Интернет» в пределах доменной зоны nowostroy-peterburg.ru.
                       </p>
                       <p>
                           2.14.    <b>«Уничтожение персональных данных»</b> означает любые действия, в результате которых Персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания Персональных данных в информационной системе Персональных данных и (или) уничтожаются материальные носители Персональных данных.
                       </p>
                       <p>
                           2.15.    <b>«Cookies»</b> означает фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта. 
                       </p>
                       <p>
                           2.16.    <b>«IP-адрес»</b> означает уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ к Сайту.
                       </p>

                        <div className="pu_p_title">3.  ПРЕДМЕТ ПОЛИТИКИ</div>

                        <p>
                            3.1.    Настоящая Политика устанавливает обязательства Оператора и Администрации Сайта по неразглашению и обеспечению режима защиты конфиденциальности Персональных данных, которые Пользователь предоставляет при оформлении записи в отношении услуг Оператора, заполнении формы обратной связи (оставлении Заявки) на Сайте. 
                        </p>
                        <p>
                            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения формы на Сайте "{site}" и включают в себя следующую информацию:
                        </p>
                        <p>
                            3.2.    Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путем заполнения формы, размещенной на Сайте при оставлении Заявки и включают в себя:
                        </p>
                        <ul>
                            <li>имя, фамилию, отчество;</li>
                            <li>адрес электронной почты (e-mail);</li>
                            <li>номер мобильного телефона;</li>
                            <li>данные, предоставленные Google Analytics и Yandex Metrika (при переходе на Сайт с использованием поисковой системы).</li>
                        </ul>
                        <p>
                            На Сайте могут использоваться Cookies и данные о посетителях от сервисов статистики посещаемости (IP адрес; информация из cookies, информация о браузере, время доступа к сайту, адрес страницы, на которой расположен рекламный блок, referer (адрес предыдущей страницы) и другие данные).
                        </p>
                        <p>
                            При помощи этих данных собирается информация о действиях Пользователей на Сайте с целью улучшения его содержания, улучшения функциональных возможностей Сайта и, как следствие, обеспечения информационной доступности услуг Оператора.
                        </p>
                        <p>
                            Пользователь может в любой момент изменить настройки своего браузера так, чтобы все файлы cookie блокировались или осуществлялось оповещение об их отправке. При этом Пользователь должен понимать, что некоторые функции и сервисы Сайта не смогут работать должным образом. С момента посещения Сайта до момента изменения настроек Пользователь дает Оператору свое согласие на обработку вышеуказанных данных. 
                        </p>

                        <div className="pu_p_title">4.  ПРИНИПЫ И ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>

                        <p>
                            4.1.    Персональные данные Пользователя Администрация Сайта обрабатывает в следующих целях, но не ограничиваясь:
                        </p>
                        <p>
                            4.1.1.  Установление с Пользователем обратной связи для уточнения потребностей Пользователя, ответов на вопросы о деятельности Оператора.
                        </p>
                        <p>
                            4.1.2.  Предоставления рекламных и маркетинговых материалов, в том числе посредством email- и sms-рассылки на email и телефон, телефонных звонков. Давая согласие на обработку персональных данных, Пользователь дает Оператору свое согласие на получение рекламы от Оператора. 
                        </p>
                        <p>
                            4.1.3.  Информирования и консультирования Пользователя по вопросам деятельности Оператора.
                        </p>
                        <p>
                            4.1.4.  Исполнения требований законодательства Российской Федерации.
                        </p>
                        <p>
                            4.1.5.  Обнаружения, предотвращения, смягчения последствий и расследования мошеннических или незаконных действий в отношении Оператора.
                        </p>
                        <p>
                            4.2.    Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для целей сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и Сайта и его содержания.
                        </p>
                        <p>
                            4.3.    При обработке Персональных данных Оператор придерживается следующих принципов:
                        </p>
                        <p>
                            — Законности.<br/>
                            — Ограничения обработки Персональных данных достижением конкретных, заранее определенных и законных целей̆.<br/>
                            — Недопущения обработки Персональных данных, несовместимой̆ с целями сбора Персональных данных.<br/>
                            — Недопущения объединения баз данных, содержащих Персональные данные, обработка которых осуществляется в целях, несовместимых между собой̆.

                        </p>

                        <div className="pu_p_title">5.  ПОЛУЧЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>

                        <p>
                            5.1.    Обработка персональных данных Оператором и Администрацией Сайта осуществляется на срок, необходимый для выполнения целей, для которых они собирались, любым законным способом с использованием средств автоматизации или без использования таких средств посредством сети Интернет.
                        </p>
                        <p>
                            5.2.    Все Персональные данные предоставляются (собираются) непосредственно от Пользователя. Пользователь самостоятельно принимает решение о предоставлении своих Персональных данных и дает согласие на их обработку Оператором и Администрацией Сайта свободно, своей волей и в своем интересе. 
                        </p>
                        <p>
                            5.3.    Согласие на обработку персональных данных предоставляется пользователем посредством проставления Пользователем «галочки» в соответствующей веб-форме напротив утверждения «Я даю свое согласие ООО «ЛИДГЕН» (ИНН: 2308257764) на обработку моих персональных данных, на условиях и в соответствии с целями, указанными в Политике обработки персональных данных. Настоящим подтверждаю ознакомление и согласие с Политикой обработки персональных данных. Настоящим даю согласие на получение рекламы от ООО «ЛИДГЕН»». Согласие на обработку персональных данных и получение рекламы может быть также дано Пользователем посредством нажатия (клика) на область (кнопку) «Я принимаю», размещенную в пределах всплывающего окна на Сайте. Даная форма согласия на обработку персональных данных признается сторонами конкретной, предметной, информированной, сознательной и однозначной.
                        </p>
                        <p>
                            5.4.    Отдельного письменного или устного согласия от Пользователя, выполнившего действие, указанное в п. 5.3. Политики, не требуется, в т.ч. не требуется получения отдельного согласия в телефонном звонке при установлении связи с Пользователем. 
                        </p>
                        <p>
                            5.5.    Персональные данные, которые будут получены от Пользователя в рамках телефонного звонка с Пользователем, обрабатываются Оператором на основании согласия, выраженного в форме, предусмотренной п. 5.3. Политики. Отдельного письменного согласия от Пользователя не требуется. 
                        </p>
                        <p>
                            5.6.    Согласие, указанное в п. 5.3. Политики, также означает согласие Пользователя на передачу Персональных данных третьим лицам (партнерам Оператора), на поручение обработки своих Персональных данных третьими лицами (партнерами Оператора), на получение рекламы от Оператора.
                        </p>


                        <div className="pu_p_title">6.  СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ, ИХ ЗАЩИТА</div>


                        <p>
                            6.1.    Обработка Персональных данных осуществляется автоматизировано с помощью средств вычислительной техники, CRM-систем, а также иными законными способами.
                        </p>
                        <p>
                            6.2.    Хранение персональных данных производится до момента отзыва согласия на обработку персональных данных, полученного от Пользователя. Впоследствии Персональные данные подлежат уничтожению.
                        </p>
                        <p>
                            6.3.    Пользователь может в любой момент отозвать свое согласие на обработку Персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора: <a href="mailto:info@leadgen.market">info@leadgen.market</a> с пометкой «Отзыв согласия на обработку персональных данных». Пользователь уведомляется о том, что отзыв согласия на обработку персональных данных может повлечь за собой невозможность использования полного функционала Сайта. 
                        </p>
                        <p>
                            6.4.    Персональные данные Пользователей хранятся на территории Российской Федерации. Если Пользователь находится на территории, где для передачи Персональных данных в другую юрисдикцию требуется его согласие, то используя Сайт, Пользователь дает свое явное и однозначное согласие на такую передачу или хранение, и/или обработку информации в других указанных юрисдикциях, включая Россию.
                        </p>
                        <p>
                            6.5.    Администрация принимает необходимые организационные, технические и юридические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц, в том числе, но не исключая:
                        </p>
                        <p>
                            — Шифровальные средства. <br />
                            — Антивирусная защита. <br />
                            — Обнаружение и предотвращение вторжения. <br />
                            — Управления доступом. <br />
                            — Регистрация и учет. <br />
                            — Осуществление резервного копирования информации.<br />
                            — Организация нормативно-методических локальных актов, регулирующих защиту персональных данных.
                        </p>
                        <p>
                            Обеспечение необходимого и достаточного уровня безопасности Персональных данных и сохранности носителей̆ сведений, содержащих Персональные данные на всех этапах работы с ним является важнейшим условием организации деятельности Оператора.
                        </p>
                        <p>
                            6.6.    Обработка Персональных данных Пользователей без их согласия осуществляется в следующих случаях: 
                        </p>
                        <p>
                            — Персональные данные являются общедоступными.<br />
                            — По требованию уполномоченных государственных органов в случаях, предусмотренных федеральным законом.<br />
                            — Обработка Персональных данных осуществляется на основании федерального закона, устанавливающего ее цель, условия получения Персональных данных и круг субъектов, Персональные данные которых подлежат обработке, а также определяющего полномочия Оператора.<br />
                            — Обработка Персональных данных осуществляется в целях заключения и исполнения договора, одной̆ из сторон которого является субъект Персональных данных – Пользователь.<br />
                            — В иных случаях, предусмотренных федеральным законом.
                        </p>
                       
                        <div className="pu_p_title">7.  ПРАВА И ОБЯЗАННОСТИ СТОРОН ПРИ ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
                        <p>
                            7.1.    Пользователь обязан:
                        </p>
                        <p>
                            7.1.1.  Предоставить Оператору только достоверные Персональные данные и своевременно сообщать об изменении своих Персональных данных.
                        </p>
                        <p>
                            7.2.    Пользователь вправе:
                        </p>
                        <p>
                            7.2.1.  Принимать свободное решение о предоставлении своих Персональных данных, необходимых для использования Сайта, и давать согласие на их обработку, а также на отзыв своего согласия.
                        </p>
                        <p>
                            7.2.2.  Обновить, дополнить или скрыть предоставленную информацию о своих Персональных данных.
                        </p>
                        <p>
                            7.2.3.  Получать у Оператора информацию, касающуюся обработки его Персональных данных, если такое право не ограничено в соответствии с федеральными законами. 
                        </p>
                        <p>
                            7.2.4.  Требовать от Администрации уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав. Для этого необходимо уведомить Администрацию по адресу электронной почты info@leadgen.market. Запрос должен содержать сведения, подтверждающие участие Пользователя в договорных отношениях с Оператором, подпись Пользователя.
                        </p>
                        <p>
                            Рассмотрение запроса Пользователя по поводу его Персональных данных осуществляется Оператором в течение 30 (тридцати) календарных дней с момента такого обращения.
                        </p>
                        <p>
                            7.2.4.  Требовать устранения неправомерных действий со стороны Администрации Сайта, Оператора в отношении его Персональных данных.
                        </p>
                        <p>
                            7.2.5.  Обжаловать действия Администрации Сайта, Оператора в отношении его Персональных данных в Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) или в судебном порядке в случае, если гражданин считает, что Оператор и (или) Администрация Сайта осуществляют обработку его Персональных данных с нарушением требований Федерального закона № 152-ФЗ «О персональных данных».
                        </p>
                        <p>
                            7.2.6.  Совершать иные действия в соответствии с действующим законодательством Российской Федерации.
                        </p>
                        <p>
                            7.3.    Оператор обязан: 
                        </p>
                        <p>
                            7.3.1.  Безвозмездно предоставить Пользователю возможность ознакомления с Персональными данными, относящимися к нему, а также внести в них необходимые изменения при предоставлении Пользователем сведений, подтверждающих, что Персональные данные являются неполными, устаревшими, неточными или незаконно полученными. О внесенных изменениях и предпринятых мерах Оператор обязан уведомить Пользователя и принять разумные меры для уведомления третьих лиц, которым Персональные данные данного Пользователя были переданы.
                        </p>
                        <p>
                            7.3.2.  Производить обработку Персональных данных исключительно для целей, указанных в разделе 4 настоящей Политики.
                        </p>
                        <p>
                            7.3.3.  Обеспечить хранение Персональных данных в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных Персональных данных Пользователя.
                        </p>
                        <p>
                            7.3.4.  Принимать меры предосторожности для защиты конфиденциальности Персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.
                        </p>
                        <p>
                            7.3.5.  Принимать меры для обнаружения фактов несанкционированного доступа к Персональным данным и немедленного доведения этой информации до Администрации Сайта.
                        </p>
                        <p>
                            7.3.6.  Осуществлять внутренний контроль за соблюдением Администрацией Сайта законодательства Российской Федерации о Персональных данных, в том числе требований к защите Персональных данных.
                        </p>
                        <p>
                            7.3.7.  Осуществить блокирование Персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов Персональных данных на период проверки, в случае выявления недостоверных Персональных данных или неправомерных действий.
                        </p>
                        <div className="pu_p_title">8.  ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
                        <p>
                            8.1.    При передаче Персональных данных Администрация Сайта должна соблюдать следующие требования:
                        </p>
                        <p>
                            8.1.1.  Разрешать доступ к Персональным данным Пользователей только специально уполномоченным лицам, при этом указанные лица должны иметь право получать только те Персональные данные Пользователей, которые необходимы для выполнения конкретных функций.
                        </p>
                        <p>
                            8.1.2.  Осуществлять передачу Персональных данных Пользователя в соответствии с настоящей Политикой, требованиями законодательства Российской Федерации в области защиты и обработки персональных данных.
                        </p>
                        <p>
                            8.1.3.  Предоставлять доступ Пользователя к своим Персональным данным при поступлении соответствующего запроса от Пользователя.
                        </p>
                        <p>
                            8.1.4.  Передавать Персональные данные Пользователя представителям Пользователя в порядке, установленном действующим законодательством Российской Федерации и ограничивать эту информацию только теми Персональными данными Пользователя, которые необходимы для выполнения указанными представителями их функции. 
                        </p>
                        <p>
                            8.2.    Администрация Сайта вправе передавать Персональные данные Пользователя третьим лицам в следующих случаях:
                        </p>
                        <p>
                            8.2.1.  Пользователь явно выразил свое согласие на осуществление указанных действий. Оставлением заявки на Сайте Пользователь дает свое согласие на передачу предоставляемых им Персональных данных уполномоченным лицам Оператора, Администрации Сайта, партнерам Оператора если это необходимо для целей исполнения обязательств перед Пользователем (в т.ч. потенциальных обязательств) (дополнительного согласия от Пользователя на передачу Персональных данных не требуется). 
                        </p>
                        <p>
                            8.2.2.  Такая передача предусмотрена действующим законодательством Российской Федерации в рамках установленной процедуры.
                        </p>
                        <div className="pu_p_title">9.  ПРЕКРАЩЕНИЕ ОБРАБОТКИ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
                        <p>
                            9.1.    В случае выявления неточных Персональных данных при обращении Пользователя Оператор обязан осуществить блокирование Персональных данных, относящихся к этому Пользователю, с момента такого обращения на период проверки, если блокирование Персональных данных не нарушает права и законные интересы Пользователя или третьих лиц.
                        </p>
                        <p>
                            9.2.    В случае подтверждения факта неточности Персональных данных Оператор на основании сведений, представленных Пользователем, обязан уточнить Персональные данные в течение 7 (семи) рабочих дней со дня представления таких сведений и снять блокирование Персональных данных.
                        </p>
                        <p>
                            9.3.    В случае выявления неправомерной обработки Персональных данных, осуществляемой Оператором и (или) Администрацией Сайта, Оператор и (или) Администрация Сайта соответственно в срок, не превышающий 3 (трех) рабочих дней со дня этого выявления, обязаны прекратить неправомерную обработку Персональных данных.
                        </p>
                        <p>
                            9.4.    В случае если обеспечить правомерность Обработки персональных данных невозможно, Оператор в срок, не превышающий 10 (десяти) рабочих дней со дня выявления неправомерной Обработки персональных данных, обязан уничтожить такие Персональные данные. Об устранении допущенных нарушений или об уничтожении персональных данных Оператор обязан уведомить Пользователя.
                        </p>
                        <p>
                            9.5.    В случае отзыва Пользователем согласия на Обработку персональных данных Оператор обязан прекратить их обработку и в случае, если сохранение Персональных данных более не требуется для целей Обработки персональных данных, уничтожить Персональные данные в срок, не превышающий 30 (тридцати) рабочих дней со дня поступления указанного отзыва от Пользователя.
                        </p>
                        <p>
                            Оператор вправе продолжить использовать Персональные данные Пользователя по итогу рассмотрения отзыва согласия на их обработку, обеспечив обезличивание такой информации.
                        </p>
                        <p>
                            О результатах рассмотрения отзыва согласия Пользователь уведомляется по адресу электронной почты, указанному при оставлении Заявки.
                        </p>
                        <div className="pu_p_title">10. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
                        <p>
                            10.1.   Оператор при обработке Персональных данных принимает необходимые правовые, организационные и технические меры от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения Персональных данных, а также от иных неправомерных действий в отношении Персональных данных.
                        </p>
                        <p>
                            10.2.   Обеспечение безопасности Персональных данных достигается, в частности:
                        </p>
                        <p>
                            — оценкой эффективности мер по обеспечению безопасности Персональных данных до начала использования таких мер;<br/>
                            — обнаружением фактов несанкционированного доступа к Персональным данным и принятием мер по их устранению и недопущению повтора;<br/>
                            — восстановлением Персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;<br/>
                            — установлением правил доступа к Персональным данным, обрабатываемым в информационной системе Персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;<br/>
                            — проверкой наличия в договорах, заключаемых с Оператором, и включением при необходимости в договоры пунктов об обеспечении конфиденциальности персональных данных;<br/>
                            — контролем за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных.
                        </p>
                        <p>
                            10.3.   Третьи лица, получившие доступ к персональным данным по поручению Оператора, обязуются принимать необходимые организационные и технические меры к обеспечению конфиденциальности такой информации на своем персональном устройстве, с которого осуществляет обработку персональных данных.
                        </p>
                        <div className="pu_p_title">11. ПРОЦЕДУРЫ ПО ВЫЯВЛЕНИЮ И ПРЕДОТВРАЩЕНИЮ НАРУШЕНИЙ ЗАКОНОДАТЕЛЬСТВА РОССИЙСКОЙ ФЕДЕРАЦИИ В СФЕРЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
                        <p>
                            11.1.   Процедуры, направленные на выявление и предотвращение нарушений законодательства Российской Федерации в сфере персональных данных, включают в себя:
                        </p>
                        <p>
                            11.1.1. применение правовых, организационных и технических мер по обеспечению безопасности персональных данных;
                        </p>
                        <p>
                            11.1.2. осуществление внутреннего контроля соответствия обработки персональных данных законодательству Российской Федерации об обработке персональных данных и условиям настоящего Положения;
                        </p>
                        <p>
                            11.1.3. назначение лица, ответственного за организацию обработки персональных данных;
                        </p>
                        <p>
                            11.1.4. издание приказа о допуске лиц, имеющих доступ к персональным данным работников, контрагентов Оператора, к обработке персональных данных; 
                        </p>
                        <p>
                            11.1.5. ведение учета лиц, имеющих доступ к персональным данным работников, контрагентов Оператора, к обработке персональных данных;
                        </p>
                        <p>
                            11.1.6. предоставление допуска к персональным данным работников, контрагентов Оператора только после подписания обязательства об обеспечении режима конфиденциальности персональных данных вышеуказанных лиц;
                        </p>
                        <p>
                            11.1.7. получение у работников, контрагентов Оператора, предоставляющих Оператору свои персональные данные, согласия на обработку персональных данных;
                        </p>
                        <p>
                            11.1.8. издание внутреннего локального акта, определяющего порядок обработки персональных данных, предоставления доступа к персональным данным, обеспечения системы защиты персональных данных; 
                        </p>
                        <p>
                            11.1.9. ознакомление лиц, имеющих доступ к персональным данным работников, контрагентов Оператора и осуществляющих обработку таких персональных данных с законодательством Российской Федерации в области персональных данных, в том числе с требованиями к защите персональных данных и настоящей Политикой;
                        </p>
                        <p>
                            11.1.10.    недопущение доступа к полученным Оператором персональным данным лиц, которые не были в установленном порядке допущены к обработке персональных данных;
                        </p>
                        <p>
                            11.1.11.    утверждение мест хранения персональных данных, предоставленных Оператору; 
                        </p>
                        <p>
                            11.1.12.    ограничение обработки персональных данных достижением конкретных, заранее определенных и законных целей;
                        </p>
                        <p>
                            11.1.13.    осуществление обработки персональных данных в соответствии с принципами и условиями обработки персональных данных, установленными законодательством Российской Федерации в области персональных данных;
                        </p>
                        <p>
                            11.1.14.    обеспечение недопустимости осуществления обработки персональных данных, несовместимых с целями сбора персональных данных;
                        </p>
                        <p>
                            11.1.15.    обеспечение соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;
                        </p>
                        <p>
                            11.1.16.    обеспечение при обработке персональных данных точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных;
                        </p>
                        <p>
                            11.1.17.    информирование Федеральной службы по надзору в сфере связи, информационных технологий и массовых коммуникаций о каждом выявленном случае утечки персональных данных в течение 24 часов с момента его выявления с указанием подробностей инцидента; причин утечки данных; предполагаемого вреда; мер, которые принял Оператор для устранения последствий утечки;
                        </p>
                        <p>
                            11.1.18.    предоставление информации в Государственную системы обнаружения, предупреждения и ликвидации последствий компьютерных атак в случае установления инцидента утечки персональных данных в сети Интернет. 
                        </p>
                        <div className="pu_p_title">12. ОТВЕТСТВЕННОСТЬ</div>
                        <p>
                            12.1.   Оператор и Администрация Сайта, виновные в нарушении требований законодательства Российской Федерации о персональных данных, а также положений настоящей Политики, несут предусмотренную законодательством Российской Федерации ответственность.
                        </p>
                        <p>
                            12.2.   Оператор не несет ответственности за возможное нецелевое использование персональных данных и причинение какого-либо ущерба Пользователю, произошедшее вследствие:
                        </p>
                        <p>
                            — технических неполадок в программном обеспечении и в технических средствах и сетях, находящихся вне контроля Оператора;<br/>
                            — в связи с намеренным или ненамеренным использованием Сайта Оператора не по их прямому назначению третьими лицами;<br/>
                            — необеспечения конфиденциальности паролей доступа или намеренной передачи паролей доступа, иной информации с Сайта самим Пользователем при использовании Сайта другим лицам, не имеющим доступа к данной информации;<br/>
                            — неправомерных действий третьих лиц по доступу к данным Сайта, в т.ч. Персональным данным.
                        </p>
                        <p>
                            12.3.   Оператор не несет ответственности за Обработку персональных данных третьих лиц, которые Пользователь сообщил как свои собственные. Риск привлечения к ответственности в этом случае несет Пользователь, предоставивший недостоверные данные.
                        </p>
                        <div className="pu_p_title">13. РАЗРЕШЕНИЕ СПОРОВ</div>
                        <p>
                            13.1.   В случае возникновения вопросов и претензий со стороны Пользователя до обращения в суд обязательным является предъявление претензии (письменного предложения или предложения в электронном виде по адресу электронной почты: <a href="mailto:info@leadgen.market">info@leadgen.market</a> о добровольном урегулировании спора) к Оператору. В случае обращения по электронной почте необходимо указать в форме письма «Претензия», а прилагаемый документ должен в обязательном порядке содержать номер и серию основного документа, удостоверяющего личность Пользователя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие Пользователя в договорных отношениях с Оператором, подпись Пользователя.
                        </p>
                        <p>
                            13.2.   Получатель претензии в течение 30 (тридцати) календарных дней со дня получения претензии тем же способом, каким была направлена претензия (в электронном виде) уведомляет отправителя претензии о результатах рассмотрения претензии. 
                        </p>
                        <p>
                            13.3.   Споры сторон, которые не удалось урегулировать в претензионном порядке, разрешаются в суде по месту нахождения Оператора.
                        </p>
                        <p>
                            13.4.   К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором применяется действующее на момент возникновения претензии законодательство Российской Федерации.
                        </p>
                        <div className="pu_p_title">14. ПРОЧИЕ УСЛОВИЯ</div>
                        <p>
                            14.1.   Оператор вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователей.
                        </p>
                        <p>
                            14.2.   Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                        </p>
                        <p>
                            14.3.   Предложения и замечания для внесения изменений в Политику конфиденциальности следует направлять Оператору по адресу электронной почты: <a href="mailto:info@leadgen.market">info@leadgen.market</a>.
                        </p>
                        <p>
                            14.4.   При Обработке персональных данных Оператор не осуществляет специально проверку наличия особого режима Обработки персональных данных, установленного законодательством стран, к юрисдикции которого относятся отдельные Пользователи. Если Пользователь является резидентом государства с особым режимом защиты Персональных данных, например, в Европейской экономической зоне (EEA), и получает доступ к Сайту из стран Европы, Оператор предпринимает все разумные меры для обеспечения соблюдения таких требований законодательства о защите Персональных данных, установленных соответствующим государством. Для этого Пользователь обязан уведомить Оператора о наличии особого режима защиты его Персональных данных путем обращения в личное сообщение по адресу электронной почты <a href="mailto:info@leadgen.market">info@leadgen.market</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Politic