const JkClass = {
    Econom: 'Эконом',
    Buisness: 'Бизнес',
    Elit: 'Элитный'
};

const FlatType = {
    Studio: 0,
    OneRoomFlat: 1,
    TwoRoomedFlat: 2,
    ThreeRoomedFlat: 3,
    FourRoomedFlat: 4,
    FiveAndMoreRoomedFlat: 5,
};

const QuestionType = {
    SimpleQuestion: 0,
    RaionsQuestion: 1,
    RoomsQuestion: 2,
    ClassQuestion: 3,
    CompletionQuestion: 4,
    PriceQuestionSlider: 5,
    PriceQuestionVariants: 6,
    DistrictQuestion: 7,
};

export {
    JkClass,
    FlatType,
    QuestionType
}