import React, { useEffect } from "react"
import BtnComponent from "../btn-component"
import CityTitle from "../step-title"
import parse from 'html-react-parser'
import { useState } from "react"
import { startQuizDataLayer } from "../../utils/send-data"
import Cookies from 'js-cookie';


const MainScreen = ({ params, nextStep, setShowPolitics }) => {
    const [shPolicy, setShPolicy] = useState(true)
    const [cookieTitle, setCookieTitle] = useState(false)

    useEffect(() => {
        let title = Cookies.get('title')
        if (title) {
            while (title.indexOf('+') !== -1) {
                title = title.replace('+', ' ')
            }

        }
        setCookieTitle(title)
    }, [])
    console.log(params.title)

    return <React.Fragment>
        <section class="main_wm h100" style={{ background: params.background != "" && params.background !== undefined ? `url("/file-store/${params.background}") center / cover no-repeat` : `url("/templates/purple_template/build/img/main_bg.jpg") center / cover no-repeat` }}>
            <div class="sect_wm">

                <div class="data_step main_page act" id="main_page">
                    <div class="m_subtit" style={{ color: "white" }}> Сервис по подбору квартир - более <span>50 000</span> предложений! </div>
                    {cookieTitle ? <h1>{cookieTitle}</h1> : <h1 class="">{!domainSettings.title || domainSettings.title == "" ? "Недорогие новостройки" : domainSettings.title}</h1>}
                    <div className="m_list">{params.sub_title === undefined || params.sub_title == "" ? parse("<span> - персональная подборка</span><span> - квартиры напрямую от застройщика</span><span> - акции, скидки, спецпредложения</span>") : parse(params.sub_title)}</div>
                    <BtnComponent text={"Начать быстрый поиск"} params={params} clickFunct={(e) => { startQuizDataLayer(); nextStep(e) }} />
                </div>
                {(domainSettings.domain.politics && shPolicy) ? <div class="politic">
                    <p>
                        Мы используем файлы cookie, чтобы убедиться, что наш веб-сайт работает должным образом. Вы можете продолжить пользоваться нашим сайтом и принять нашу <a href="#" onClick={(e) => { e.preventDefault(); setShowPolitics(true) }} target="_blank"> Политику cookie</a>. Изменить ваши настройки cookie можно будет в любой момент.
                    </p>
                    <div class="politic_btn" onClick={(e) => { e.preventDefault(); setShPolicy(false) }}>Я принимаю</div>
                </div> : ""}
            </div>
            <div class="step_dec"></div>
        </section>
    </React.Fragment>
}



export default MainScreen