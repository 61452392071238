import { useEffect, useState } from "react"
import { domainSettingsForTest } from "../../utils"
import FinishSteps from "../finish-steps"
import MainScreen from "../main-screen"
import Step from "../steps"
import Cookies from 'js-cookie'
import Politic from "../politic/politic"

const Router = () => {
    const [step, setStep] = useState(null)
    const [params, setParams] = useState({})
    const [raionsName, setRaionsName] = useState("")
    const [roomsName, setRoomsName] = useState("")
    const [sdachaName, setSdachaName] = useState("")
    const [raionsPrice, setRaionsPrice] = useState("")
    const [shPolicy, setShPolicy] = useState(true)

    const [ignoreFirstStep, setIgnore] = useState(false)

    const [showPolitic, setShowPolitics] = useState(false)

    const [form, setForm] = useState({})
    const nextStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step === null) {
            setStep(0)
        } else {
            setStep(step + 1)
        }
    }
    const prevStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step - 1 < 0) {
            setStep(0)
        } else {
            setStep(step - 1)
        }
    }

    useEffect(() => {
        if (domainSettings.domain.yandex != "") {
            try {
                setTimeout(() => {
                    ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                        domainSettings.domain.clientID = clientID
                    })
                }, 3000)
            } catch (e) { }
        }
        setParams(domainSettings.domain)
    }, [])

    return <div className="container_main" style={{ background: params.background != "" && params.background !== undefined ? `url("/file-store/${params.background}") center / cover no-repeat` : `url("/templates/purple_template/build/img/main_bg.jpg") center / cover no-repeat` }}>
        {showPolitic ? <Politic site={domainSettings.domain.url} date={domainSettings.domain.politicsDate != "" ? domainSettings.domain.politicsDate : domainSettings.domain.created_at.split(" ")[0]} close={() => { setShowPolitics(false) }} /> : <></>}
        {(() => {
            switch (true) {
                case step === null:
                    return <MainScreen params={params} nextStep={nextStep} setShowPolitics={setShowPolitics} />
                case params.steps.length <= step:
                    return <FinishSteps form={form} setForm={setForm} params={params} setShowPolitics={setShowPolitics} />;
                default:
                    return <Step step={params.steps[step]} params={params} index={step} length={params.steps.length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />;
            }
        })()}
    </div>
}

export default Router