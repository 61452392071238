import { useState } from "react"

const HoveredComponent = ({ params, text, link, photo, count, onClick }) => {


    return <li>
        <a onClick={onClick} style={{ textDecoration: "none" }} href={link} target="_blank">
            {text!="Посмотреть каталог полностью"?<div class="jk_img">
                <img src={ photo} />
            </div>:""}
            <span>{text}</span>
            {/*<div class="apart_count">{count} квартир</div>*/}
        </a>
    </li>
}

export default HoveredComponent